import React, { useEffect, useState } from 'react';
import { getAllClients, getPaymentsByClient } from '../utils/api';
import ClientList from '../components/ClientList';
import '../styles/Dashboard.css';

function OverdueClients() {
  const [overdueClients, setOverdueClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOverdueClients = async () => {
      try {
        const allClients = await getAllClients();
        const clientsWithPayments = await Promise.all(
          allClients.map(async (client) => {
            const response = await getPaymentsByClient(client.id);
            const payments = Array.isArray(response) ? response : 
                           Array.isArray(response?.payments) ? response.payments : [];
            return { ...client, payments };
          })
        );

        // Фильтруем клиентов с просроченными платежами
        const overdue = clientsWithPayments.filter(client => {
          if (!client.payments || !client.contractDate) return false;

          // Получаем последний платёж
          const sortedPayments = [...client.payments].sort((a, b) => b.month - a.month);
          const lastPayment = sortedPayments[0];
          const lastPaidMonth = lastPayment ? lastPayment.month : 0;

          // Вычисляем дату следующего платежа
          const nextPaymentDate = new Date(client.contractDate);
          nextPaymentDate.setMonth(nextPaymentDate.getMonth() + lastPaidMonth + 1);

          // Проверяем, просрочен ли платёж
          return nextPaymentDate < new Date() && client.remainingDebt > 0;
        });

        setOverdueClients(overdue);
        setIsLoading(false);
      } catch (error) {
        console.error('Ошибка при загрузке клиентов:', error);
        setIsLoading(false);
      }
    };

    fetchOverdueClients();
  }, []);

  return (
    <div className="dashboard">
      <div className="main-content">
        <h1>Просроченные платежи</h1>
        {isLoading ? (
          <p>Загрузка...</p>
        ) : (
          <ClientList clients={overdueClients} />
        )}
      </div>
    </div>
  );
}

export default OverdueClients;