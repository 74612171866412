// ClientDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  getClientById,
  updateClient,
  getPaymentsByClient,
  getChangeLog,
  getClientDocuments,
  uploadDocument,
  deleteDocument,
} from '../utils/api';
import ClientCard from '../components/ClientCard';
import PaymentCalculator from '../components/PaymentCalculator';
import DocumentManager from '../components/DocumentManager';
import ChangeLog from '../components/ChangeLog';
import Tabs from '../components/Tabs';
import Analytics from '../components/Analytics';
import CompanyCard from '../components/CompanyCard';
import '../styles/ClientDetails.css';

// Словарь меток полей
export const fieldLabels = {
  fullName: 'ФИО клиента',
  contractDate: 'Дата договора',
  contractNumber: 'Номер договора',
  phone: 'Телефон',
  address: 'Адрес',
  paymentType: 'Способ оплаты',
  workType: 'Вид работ',
  passportFile: 'Файл паспорта',
  contractAmount: 'Сумма договора',
  prepaymentAmount: 'Сумма предоплаты',
  contractTerm: 'Срок договора (месяцев)',
  status: 'Статус договора',
  isProblematic: 'Проблемный клиент',
  surveyorName: 'ФИО замерщика',
  surveyorSalary: 'Зарплата замерщика',
  managerName: 'ФИО менеджера',
  managerSalary: 'Зарплата менеджера',
  installerName: 'ФИО монтажника',
  installerSalary: 'Зарплата монтажника',
  materialCosts: 'Затраты на материалы',
  additionalExpenses: 'Дополнительные расходы',
  remainingDebt: 'Остаток задолженности',
  paidAmount: 'Оплаченная сумма',
  monthlyPaymentAmount: 'Сумма ежемесячного платежа',
  notes: 'Примечания'
};

function ClientDetails() {
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({});
  const [payments, setPayments] = useState([]);
  const [changes, setChanges] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notes, setNotes] = useState('');
  const [isSaving, setSaving] = useState(false);

  const fetchClientData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Загружаем данные клиента
      const clientData = await getClientById(id);
      console.log('Полученные данные клиента:', clientData);
      if (!clientData) {
        throw new Error('Клиент не найден');
      }
      setClient(clientData);
      setEditData(clientData);
      setNotes(clientData.notes || '');

      // Загружаем платежи
      try {
        const clientPayments = await getPaymentsByClient(id);
        console.log('Полученные платежи:', clientPayments);
        let processedPayments;
        if (Array.isArray(clientPayments)) {
          processedPayments = clientPayments;
        } else if (clientPayments && Array.isArray(clientPayments.payments)) {
          processedPayments = clientPayments.payments;
        } else {
          processedPayments = [];
        }
        processedPayments = processedPayments.map(payment => ({
          ...payment,
          status: payment.status?.toLowerCase() === 'paid' ? 'paid' :
                 payment.status?.toLowerCase() === 'partial' ? 'partial' : 'pending'
        }));
        console.log('Обработанные платежи:', processedPayments);
        setPayments(processedPayments);
      } catch (paymentError) {
        console.warn("Ошибка загрузки платежей:", paymentError);
        setPayments([]);
      }

      // Загружаем историю изменений
      try {
        const changeLogs = await getChangeLog(id);
        setChanges(Array.isArray(changeLogs) ? changeLogs : []);
      } catch (changeError) {
        console.warn("Ошибка загрузки истории изменений:", changeError);
        setChanges([]);
      }

      // Загружаем документы
      try {
        const clientDocuments = await getClientDocuments(id);
        setDocuments(Array.isArray(clientDocuments) ? clientDocuments : []);
      } catch (docError) {
        console.warn("Ошибка загрузки документов:", docError);
        setDocuments([]);
      }

    } catch (error) {
      console.error("Ошибка при загрузке данных клиента:", error);
      setError(error.message || 'Произошла ошибка при загрузке данных');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientData();
  }, [id]);

  const calculateTotalPaid = () => {
    if (!Array.isArray(payments) || payments.length === 0) return 0;
    return payments.reduce((sum, payment) => {
      const amount = Number(payment?.amount) || 0;
      return sum + amount;
    }, 0);
  };

  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSave = async () => {
    try {
      await updateClient(id, editData);
      await fetchClientData();
      setIsEditing(false);
    } catch (error) {
      console.error('Ошибка при сохранении данных клиента:', error);
      alert('Произошла ошибка при сохранении данных');
    }
  };

  const handleUpload = async (file) => {
    try {
      const newDocument = await uploadDocument(id, file);
      if (newDocument) {
        setDocuments(prev => [...prev, newDocument]);
      }
    } catch (error) {
      console.error("Ошибка при загрузке документа:", error);
      alert('Произошла ошибка при загрузке документа');
    }
  };

  const handleDelete = async (documentId) => {
    try {
      await deleteDocument(documentId);
      setDocuments(prev => prev.filter(doc => doc.id !== documentId));
    } catch (error) {
      console.error("Ошибка при удалении документа:", error);
      alert('Произошла ошибка при удалении документа');
    }
  };

  const handleSaveNotes = async () => {
    try {
      setSaving(true);
      await updateClient(id, { notes });
      await fetchClientData();
    } catch (error) {
      console.error('Ошибка при сохранении заметок:', error);
      alert('Произошла ошибка при сохранении заметок');
    } finally {
      setSaving(false);
    }
  };

  const handleAnalyticsUpdate = async (updatedData) => {
    try {
      await updateClient(id, updatedData);
      await fetchClientData();
    } catch (error) {
      console.error('Ошибка при сохранении данных аналитики:', error);
      alert('Произошла ошибка при обновлении аналитики');
    }
  };

  const getInputType = (key) => {
    switch(key) {
      case 'contractDate':
        return 'date';
      case 'contractAmount':
      case 'prepaymentAmount':
      case 'surveyorSalary':
      case 'managerSalary':
      case 'installerSalary':
      case 'materialCosts':
      case 'additionalExpenses':
      case 'monthlyPaymentAmount':
        return 'number';
      case 'contractTerm':
        return 'number';
      case 'isProblematic':
        return 'checkbox';
      case 'notes':
        return 'textarea';
      default:
        return 'text';
    }
  };

  if (loading) {
    return <div className="loading">Загрузка данных клиента...</div>;
  }

  if (error) {
    return <div className="error">Ошибка: {error}</div>;
  }

  const canCalculatePayments = client && 
    client.contractAmount && 
    client.contractTerm && 
    client.contractDate;

  const tabs = [
    {
      label: 'Основные',
      content: isEditing ? (
        <div className="edit-section">
          <h2>Редактирование клиента</h2>
          <form className="edit-form">
          {Object.entries(editData).map(([key, value]) => {
            if (['id', 'createdAt', 'updatedAt'].includes(key)) return null;
            
            const inputType = getInputType(key);
            
            if (key === 'isProblematic') {
              return (
                <div key={key} className="form-group checkbox-group">
                  <label>
                    <input
                      type="checkbox"
                      name={key}
                      checked={value || false}
                      onChange={handleEditChange}
                    />
                    {fieldLabels[key] || 'Проблемный клиент'}
                  </label>
                </div>
              );
            }

            return (
              <div key={key} className="form-group">
                <label>{fieldLabels[key] || key}:</label>
                {inputType === 'textarea' ? (
                  <textarea
                    name={key}
                    value={value || ''}
                    onChange={handleEditChange}
                    rows="4"
                  />
                ) : (
                  <input
                    type={inputType}
                    name={key}
                    value={value || ''}
                    onChange={handleEditChange}
                    step={inputType === 'number' ? '0.01' : undefined}
                  />
                )}
              </div>
            );
          })}
          </form>
          <div className="button-group">
            <button className="save-button" onClick={handleSave}>Сохранить</button>
            <button className="cancel-button" onClick={() => setIsEditing(false)}>Отмена</button>
          </div>
        </div>
      ) : (
        <ClientCard 
          client={client ? { 
            ...client, 
            paidAmount: calculateTotalPaid() 
          } : null} 
          onEdit={() => setIsEditing(true)} 
        />
      ),
    },
    { 
      label: 'Платежи', 
      content: canCalculatePayments ? (
        <PaymentCalculator 
          client={client} 
          payments={payments} 
          onPaymentAdded={fetchClientData}
          onError={(error) => console.error('Ошибка в калькуляторе платежей:', error)}
        />
      ) : (
        <div className="warning">
          Для расчета платежей необходимо указать сумму договора, срок и дату договора
        </div>
      )
    },
    {
      label: 'Информация',
      content: (
        <div className="info-section">
          <div className="notes-container">
            <div className="notes-header">
              <div className="button-group">
                <button 
                  className="save-button"
                  onClick={handleSaveNotes}
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <span>Сохранение...</span>
                  ) : (
                    <span>Сохранить</span>
                  )}
                </button>
              </div>
            </div>
            <textarea
              className="notes-textarea"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Добавьте заметки о клиенте..."
              spellCheck="false"
            />
          </div>
        </div>
      )
    },
    {
      label: 'Документы',
      content: (
        <DocumentManager
          documents={documents}
          onUpload={handleUpload}
          onDelete={handleDelete}
        />
      ),
    },
    { 
      label: 'Карточка компании', 
      content: <CompanyCard /> 
    },
    { 
      label: 'Изменения', 
      content: <ChangeLog changes={changes} /> 
    },
    { 
      label: 'Аналитика', 
      content: client && (
        <Analytics 
          data={{ 
            ...client, 
            payments,
            paidAmount: calculateTotalPaid() 
          }} 
          onUpdate={handleAnalyticsUpdate} 
        />
      ),
    },
  ];

  return (
    <div className="client-details">
      <h1>Детали клиента</h1>
      {client ? <Tabs tabs={tabs} /> : <p>Клиент не найден</p>}
    </div>
  );
}

export default ClientDetails;