import axios from 'axios';

const API_URL = 'https://skyneocrm.ru/api';

// Авторизация

export const login = async ({ username, password }) => {
  try {
    const { data } = await axios.post(`${API_URL}/login`, { username, password });
    localStorage.setItem('token', data.token);
    localStorage.setItem('role', data.role);
    localStorage.setItem('managerId', data.managerId);
    return { success: true, role: data.role }; // Возвращаем объект с флагом успеха и ролью
  } catch (error) {
    console.error('Ошибка входа:', error);
    return { success: false, error: error.response?.data?.message || 'Ошибка входа' };
  }
};

export const register = async ({ username, password, role }) => {
  try {
    await axios.post(`${API_URL}/register`, { username, password, role });
    // После успешной регистрации сразу выполняем вход
    return await login({ username, password });
  } catch (error) {
    console.error('Ошибка регистрации:', error);
    return { success: false, error: error.response?.data?.message || 'Ошибка регистрации' };
  }
};

// Получение всех клиентов
export const getAllClients = async () => {
  const { data } = await axios.get(`${API_URL}/clients`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  });
  return data;
};

// Получение клиента по ID
export const getClientById = async (id) => {
  const { data } = await axios.get(`${API_URL}/clients/${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  });
  return data;
};

// Создание клиента
export const createClient = async (clientData) => {
  try {
    const { data } = await axios.post(`${API_URL}/clients`, clientData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка создания клиента:', error);
    throw error;
  }
};

// Обновление клиента
export const updateClient = async (id, clientData) => {
  try {
    const { data } = await axios.put(`${API_URL}/clients/${id}`, clientData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка обновления клиента:', error);
    throw error;
  }
};

// Обновление заметок клиента
export const updateClientNotes = async (id, notes) => {
  try {
    const { data } = await axios.put(`${API_URL}/clients/${id}/notes`, { notes }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка обновления заметок:', error);
    throw error;
  }
};

// Удаление клиента
export const deleteClient = async (id) => {
  try {
    await axios.delete(`${API_URL}/clients/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
  } catch (error) {
    console.error('Ошибка удаления клиента:', error);
    throw error;
  }
};

// Получение платежей по ID клиента
export const getPaymentsByClient = async (clientId) => {
  try {
    const { data } = await axios.get(`${API_URL}/clients/${clientId}/payments`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка при получении платежей клиента:', error);
    throw error;
  }
};

// Добавление платежа
export const addPayment = async (paymentData) => {
  console.log("Отправка данных платежа:", paymentData);
  try {
    const { data } = await axios.post(`${API_URL}/payments`, paymentData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    console.log("Платеж успешно добавлен:", data);
    return data;
  } catch (error) {
    console.error('Ошибка добавления платежа:', error);
    throw error;
  }
};

// Обновление статуса платежа
export const updatePaymentStatus = async (paymentId, statusData) => {
  console.log(`Обновление статуса платежа с ID ${paymentId}:`, statusData);
  try {
    const { data } = await axios.put(`${API_URL}/payments/${paymentId}`, statusData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    console.log("Статус платежа успешно обновлен:", data);
    return data;
  } catch (error) {
    console.error('Ошибка обновления статуса платежа:', error);
    throw error;
  }
};

// В файле api.js обновите функцию getChangeLog
export const getChangeLog = async (clientId) => {
  try {
    const { data } = await axios.get(`${API_URL}/clients/${clientId}/changelog`, {
      headers: { 
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.error('Ошибка при получении логов изменений:', error);
    return [];
  }
};

export const getGlobalAnalytics = async (range = 'year') => {
  try {
    const { data } = await axios.get(`${API_URL}/analytics/global`, {
      params: { range },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка при получении глобальной аналитики:', error);
    throw error;
  }
};

// Получение документов клиента
export const getClientDocuments = async (clientId) => {
  try {
    const { data } = await axios.get(`${API_URL}/clients/${clientId}/documents`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка при получении документов клиента:', error);
    throw error;
  }
};

// Загрузка документа клиента
export const uploadDocument = async (clientId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('clientId', clientId);

  try {
    const { data } = await axios.post(`${API_URL}/documents/upload`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    console.error('Ошибка загрузки документа:', error);
    throw error;
  }
};

// Скачивание документа клиента
export const downloadDocument = async (documentId) => {
  try {
    const response = await axios.get(`${API_URL}/documents/download/${documentId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      responseType: 'blob',
    });

    const contentDisposition = response.headers['content-disposition'];
    let filename = `document-${documentId}`;
    
    if (contentDisposition) {
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Ошибка при скачивании документа:', error);
    throw error;
  }
};

// Удаление документа клиента
export const deleteDocument = async (documentId) => {
  try {
    await axios.delete(`${API_URL}/documents/${documentId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
  } catch (error) {
    console.error('Ошибка при удалении документа:', error);
    throw error;
  }
};

// Загрузка файла компании
export const uploadCompanyFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const { data } = await axios.post(`${API_URL}/company/file`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    console.error('Ошибка загрузки файла компании:', error);
    throw error;
  }
};

// Скачивание файла компании
export const downloadCompanyFile = async () => {
  try {
    const response = await axios.get(`${API_URL}/company/file`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      responseType: 'blob',
    });

    const contentDisposition = response.headers['content-disposition'];
    let filename = 'company-file';
    
    if (contentDisposition) {
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Ошибка при скачивании файла компании:', error);
    throw error;
  }
};

export const getCompanyFileInfo = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/company/file/info`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка при получении информации о файле компании:', error);
    throw error;
  }
};