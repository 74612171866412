import React, { useState, useEffect } from 'react';
import { addPayment, updatePaymentStatus } from '../utils/api';
import '../styles/PaymentCalculatorStyles.css';

function PaymentCalculator({ client, payments: initialPayments }) {
  const [paymentSchedule, setPaymentSchedule] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);
  const [remainingDebt, setRemainingDebt] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showPartialPaymentDialog, setShowPartialPaymentDialog] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [partialAmount, setPartialAmount] = useState('');

  useEffect(() => {
    if (client && initialPayments) {
      calculatePayments();
    }
  }, [client, initialPayments]);

  const calculatePayments = () => {
    if (!client || !Array.isArray(initialPayments)) return;

    const startDate = new Date(client.contractDate);
    const totalAmount = client.contractAmount || 0;
    const prepayment = client.prepaymentAmount || 0;
    const contractTerm = client.contractTerm || 12;
    const baseMonthlyPayment = (totalAmount - prepayment) / contractTerm;

    const monthPaymentsMap = new Map();
    let adjustedAmounts = new Array(contractTerm + 1).fill(baseMonthlyPayment);

    initialPayments.forEach(payment => {
        if (!monthPaymentsMap.has(payment.month)) {
            monthPaymentsMap.set(payment.month, []);
        }
        monthPaymentsMap.get(payment.month).push(payment);
    });

    let runningPaidAmount = 0;
    for (let month = 1; month <= contractTerm; month++) {
        const monthPayments = monthPaymentsMap.get(month) || [];
        const monthTotalPaid = monthPayments.reduce((sum, p) => sum + Number(p.amount), 0);
        runningPaidAmount += monthTotalPaid;

        const hasPartialPayment = monthPayments.some(p => p.status === 'partial');
        if (hasPartialPayment) {
            const remaining = totalAmount - prepayment - runningPaidAmount;
            const remainingMonths = contractTerm - month;
            if (remainingMonths > 0) {
                const newMonthlyAmount = remaining / remainingMonths;
                for (let futureMonth = month + 1; futureMonth <= contractTerm; futureMonth++) {
                    adjustedAmounts[futureMonth] = newMonthlyAmount;
                }
            }
        }
    }

    const schedule = [];
    for (let month = 1; month <= contractTerm; month++) {
        const dueDate = new Date(startDate);
        dueDate.setMonth(startDate.getMonth() + month);

        const monthPayments = monthPaymentsMap.get(month) || [];
        const totalMonthPaid = monthPayments.reduce((sum, p) => sum + Number(p.amount), 0);
        
        let status = 'Не оплачено';
        if (totalMonthPaid >= adjustedAmounts[month]) {
            status = 'Оплачено';
        } else if (totalMonthPaid > 0) {
            status = 'Частично оплачено';
        }

        schedule.push({
            month,
            date: dueDate.toISOString(),
            initialAmount: adjustedAmounts[month],
            paidAmount: totalMonthPaid,
            status,
            paymentMethod: monthPayments[0]?.paymentMethod || '',
            paymentId: monthPayments[0]?.id,
            remainingForMonth: Math.max(0, adjustedAmounts[month] - totalMonthPaid)
        });
    }

    const totalPaidAmount = initialPayments.reduce((sum, payment) => sum + Number(payment.amount), 0);
    const currentRemainingDebt = Math.max(0, totalAmount - prepayment - totalPaidAmount);

    setPaymentSchedule(schedule);
    setTotalPaid(totalPaidAmount);
    setRemainingDebt(currentRemainingDebt);
    setMonthlyPayment(adjustedAmounts[schedule.length > 0 ? schedule[schedule.length - 1].month : 1]);
  };

  const handleFullPayment = async (monthIndex) => {
    const payment = paymentSchedule[monthIndex];
    if (!payment || payment.status === 'Оплачено' || isLoading) return;

    setIsLoading(true);
    try {
      const paymentData = {
        clientId: client.id,
        month: payment.month,
        date: new Date().toISOString(),
        initialAmount: payment.initialAmount,
        amount: payment.remainingForMonth,
        status: 'paid',
        paymentMethod: payment.paymentMethod || 'Оплата полностью'
      };

      await addPayment(paymentData);
      window.location.reload();
    } catch (error) {
      console.error('Ошибка при обработке платежа:', error);
      alert('Произошла ошибка при обработке платежа');
    } finally {
      setIsLoading(false);
    }
  };

  const openPartialPaymentDialog = (monthIndex) => {
    const payment = paymentSchedule[monthIndex];
    setSelectedPayment(payment);
    setPartialAmount('');
    setShowPartialPaymentDialog(true);
  };

  const handlePartialPayment = async () => {
    if (!selectedPayment || isLoading) return;

    const amount = parseFloat(partialAmount);
    if (isNaN(amount) || amount <= 0 || amount > selectedPayment.remainingForMonth) {
      alert('Введена некорректная сумма');
      return;
    }

    setIsLoading(true);
    try {
      const paymentData = {
        clientId: client.id,
        month: selectedPayment.month,
        date: new Date().toISOString(),
        initialAmount: selectedPayment.initialAmount,
        amount: amount,
        status: 'partial',
        paymentMethod: selectedPayment.paymentMethod || 'Частичная оплата'
      };

      await addPayment(paymentData);
      setShowPartialPaymentDialog(false);
      window.location.reload();
    } catch (error) {
      console.error('Ошибка при обработке частичного платежа:', error);
      alert('Произошла ошибка при обработке платежа');
    } finally {
      setIsLoading(false);
    }
  };

  // В начале компонента тот же код, изменяется только return

// ...предыдущий код...

return (
  <div className="payment-calculator-root">
    <div className="pc-container">
      <div className="pc-summary">
        <h2 className="pc-summary-title">Информация о платежах</h2>
        <div className="pc-summary-content">
          <p className="pc-summary-row">
            <span>Сумма договора:</span>
            <span>{client?.contractAmount?.toFixed(2) || 0} ₽</span>
          </p>
          <p className="pc-summary-row">
            <span>Предоплата:</span>
            <span>{client?.prepaymentAmount?.toFixed(2) || 0} ₽</span>
          </p>
          <p className="pc-summary-row">
            <span>Всего оплачено:</span>
            <span>{totalPaid.toFixed(2)} ₽</span>
          </p>
          <p className="pc-summary-row">
            <span>Остаток долга:</span>
            <span>{remainingDebt.toFixed(2)} ₽</span>
          </p>
          <p className="pc-summary-row">
            <span>Ежемесячный платеж:</span>
            <span>{monthlyPayment.toFixed(2)} ₽</span>
          </p>
        </div>
      </div>

      <h3 className="pc-section-title">График платежей</h3>
      {paymentSchedule.length > 0 ? (
        <div className="pc-table-wrapper">
          <table className="pc-table">
            <thead>
              <tr>
                <th>Месяц</th>
                <th>Дата платежа</th>
                <th>Сумма платежа</th>
                <th>Оплачено</th>
                <th>Остаток</th>
                <th>Статус</th>
                <th>Способ оплаты</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {paymentSchedule.map((payment, index) => (
                <tr key={index}>
                  <td>{payment.month}</td>
                  <td>{new Date(payment.date).toLocaleDateString()}</td>
                  <td>{payment.initialAmount.toFixed(2)} ₽</td>
                  <td>{payment.paidAmount.toFixed(2)} ₽</td>
                  <td>{payment.remainingForMonth.toFixed(2)} ₽</td>
                  <td>
                    <span className={`pc-status ${
                      payment.status === 'Оплачено' 
                        ? 'pc-status-paid'
                        : payment.status === 'Частично оплачено'
                        ? 'pc-status-partial'
                        : 'pc-status-unpaid'
                    }`}>
                      {payment.status}
                    </span>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={payment.paymentMethod}
                      onChange={(e) => {
                        const newSchedule = [...paymentSchedule];
                        newSchedule[index].paymentMethod = e.target.value;
                        setPaymentSchedule(newSchedule);
                      }}
                      placeholder="Способ оплаты"
                      disabled={payment.status === 'Оплачено' || isLoading}
                      className="pc-input"
                    />
                  </td>
                  <td>
                    {payment.status !== 'Оплачено' && (
                      <div className="pc-actions">
                        <button
                          onClick={() => handleFullPayment(index)}
                          className="pc-btn-full"
                          disabled={isLoading}
                        >
                          {isLoading ? 'Обработка...' : 'Полностью'}
                        </button>
                        <button
                          onClick={() => openPartialPaymentDialog(index)}
                          className="pc-btn-partial"
                          disabled={isLoading}
                        >
                          {isLoading ? 'Обработка...' : 'Частично'}
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="pc-empty-message">График платежей недоступен</p>
      )}

      {showPartialPaymentDialog && selectedPayment && (
        <div className="pc-modal" onClick={() => setShowPartialPaymentDialog(false)}>
          <div className="pc-modal-content" onClick={e => e.stopPropagation()}>
            <h3 className="pc-modal-title">Частичная оплата</h3>
            <p className="pc-modal-row">
              <span>Месяц:</span>
              <span>{selectedPayment.month}</span>
            </p>
            <p className="pc-modal-row">
              <span>Сумма платежа:</span>
              <span>{selectedPayment.initialAmount.toFixed(2)} ₽</span>
            </p>
            <p className="pc-modal-row">
              <span>Уже оплачено:</span>
              <span>{selectedPayment.paidAmount.toFixed(2)} ₽</span>
            </p>
            <p className="pc-modal-row">
              <span>Осталось оплатить:</span>
              <span>{selectedPayment.remainingForMonth.toFixed(2)} ₽</span>
            </p>
            <div className="pc-form-group">
              <label className="pc-form-label">Сумма платежа:</label>
              <input
                type="number"
                value={partialAmount}
                onChange={(e) => setPartialAmount(e.target.value)}
                min="0"
                max={selectedPayment.remainingForMonth}
                step="0.01"
                className="pc-form-input"
              />
              {partialAmount && (parseFloat(partialAmount) <= 0 || 
                parseFloat(partialAmount) > selectedPayment.remainingForMonth) && (
                <div className="pc-error">
                  Сумма должна быть больше 0 и не больше остатка
                </div>
              )}
            </div>
            <div className="pc-modal-actions">
              <button 
                onClick={handlePartialPayment}
                className="pc-modal-btn pc-modal-btn-primary"
                disabled={isLoading || !partialAmount || 
                         parseFloat(partialAmount) <= 0 || 
                         parseFloat(partialAmount) > selectedPayment.remainingForMonth}
              >
                {isLoading ? 'Обработка...' : 'Оплатить'}
              </button>
              <button 
                onClick={() => setShowPartialPaymentDialog(false)}
                className="pc-modal-btn pc-modal-btn-secondary"
                disabled={isLoading}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
  );
}

export default PaymentCalculator;