// frontend/src/pages/CreateClient.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '../utils/api';

function CreateClient() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    address: '',
    contractDate: '',
    contractNumber: '',
    workType: '',
    paymentType: '',
    contractAmount: '',
    prepaymentAmount: '',
    contractTerm: '',
    status: '',
    isProblematic: false,
    surveyorName: '',
    surveyorSalary: '',
    managerName: '',
    managerSalary: '',
    installerName: '',
    installerSalary: '',
    materialCosts: '',
    additionalExpenses: '',
    remainingDebt: '',
    paidAmount: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cleanedData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, value || null])
    );
    try {
      await createClient(cleanedData);
      navigate('/');
    } catch (error) {
      console.error('Ошибка при создании клиента:', error);
    }
  };

  return (
    <div className="create-client">
      <h1>Создать клиента</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          placeholder="ФИО"
          required
        />
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Телефон"
          required
        />
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder="Адрес"
          required
        />
        <input
          type="date"
          name="contractDate"
          value={formData.contractDate}
          onChange={handleChange}
          placeholder="Дата договора"
        />
        <input
          type="text"
          name="contractNumber"
          value={formData.contractNumber}
          onChange={handleChange}
          placeholder="Номер договора"
          required
        />
        <input
          type="text"
          name="workType"
          value={formData.workType}
          onChange={handleChange}
          placeholder="Вид работ"
          required
        />
        <input
          type="text"
          name="paymentType"
          value={formData.paymentType}
          onChange={handleChange}
          placeholder="Вид оплаты"
          required
        />
        <input
          type="number"
          name="contractAmount"
          value={formData.contractAmount}
          onChange={handleChange}
          placeholder="Сумма договора"
          required
        />
        <input
          type="number"
          name="prepaymentAmount"
          value={formData.prepaymentAmount}
          onChange={handleChange}
          placeholder="Предоплата"
          required
        />
        <input
          type="number"
          name="contractTerm"
          value={formData.contractTerm}
          onChange={handleChange}
          placeholder="Срок (в месяцах)"
          required
        />
        <select name="status" value={formData.status} onChange={handleChange}>
          <option value="">Выберите статус</option>
          <option value="active">Активный</option>
          <option value="completed">Завершен</option>
          <option value="canceled">Отменен</option>
        </select>
        <label>
          <input
            type="checkbox"
            name="isProblematic"
            checked={formData.isProblematic}
            onChange={handleChange}
          />
          Проблемный клиент
        </label>
        <input
          type="text"
          name="surveyorName"
          value={formData.surveyorName}
          onChange={handleChange}
          placeholder="Имя замерщика"
        />
        <input
          type="number"
          name="surveyorSalary"
          value={formData.surveyorSalary}
          onChange={handleChange}
          placeholder="Зарплата замерщика"
        />
        <input
          type="text"
          name="managerName"
          value={formData.managerName}
          onChange={handleChange}
          placeholder="Имя менеджера"
        />
        <input
          type="number"
          name="managerSalary"
          value={formData.managerSalary}
          onChange={handleChange}
          placeholder="Зарплата менеджера"
        />
        <input
          type="text"
          name="installerName"
          value={formData.installerName}
          onChange={handleChange}
          placeholder="Имя монтажника"
        />
        <input
          type="number"
          name="installerSalary"
          value={formData.installerSalary}
          onChange={handleChange}
          placeholder="Зарплата монтажника"
        />
        <input
          type="number"
          name="materialCosts"
          value={formData.materialCosts}
          onChange={handleChange}
          placeholder="Расходы на материалы"
        />
        <input
          type="number"
          name="additionalExpenses"
          value={formData.additionalExpenses}
          onChange={handleChange}
          placeholder="Дополнительные расходы"
        />
        <input
          type="number"
          name="remainingDebt"
          value={formData.remainingDebt}
          onChange={handleChange}
          placeholder="Остаток долга"
        />
        <input
          type="number"
          name="paidAmount"
          value={formData.paidAmount}
          onChange={handleChange}
          placeholder="Оплаченная сумма"
        />
        <button type="submit">Создать клиента</button>
      </form>
    </div>
  );
}

export default CreateClient;
