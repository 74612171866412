import React, { useState, useEffect } from 'react';
import '../styles/Analytics.css';

function Analytics({ data, onUpdate }) {
  const [editableData, setEditableData] = useState(data);

  useEffect(() => {
    setEditableData(data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableData((prevData) => ({
      ...prevData,
      [name]: parseFloat(value) || 0,
    }));
  };

  const calculateActualPaidAmount = () => {
    if (!data.payments) return 0;
    return data.payments.reduce((sum, payment) => sum + payment.amount, 0);
  };

  const calculateRemaining = () => {
    const actualPaidAmount = calculateActualPaidAmount();
    const {
      contractAmount = 0,
      prepaymentAmount = 0,
    } = editableData;

    return contractAmount - prepaymentAmount - actualPaidAmount;
  };

  // Изменённый расчёт прибыли (без учёта доп. расходов)
  const calculateProfit = () => {
    const {
      contractAmount = 0,
      managerSalary = 0,
      surveyorSalary = 0,
      installerSalary = 0,
      materialCosts = 0,
    } = editableData;

    return contractAmount - managerSalary - surveyorSalary - installerSalary - materialCosts;
  };

  const totalWithAdditionalExpenses = () => {
    return (editableData.contractAmount || 0) + (editableData.additionalExpenses || 0);
  };

  const handleSave = () => {
    onUpdate(editableData);
  };

  const actualPaidAmount = calculateActualPaidAmount();

  return (
    <div className="analytics">
      <h2>Аналитика клиента</h2>
      <table>
        <tbody>
          <tr>
            <td>Сумма договора:</td>
            <td>
              <input
                type="number"
                name="contractAmount"
                value={editableData.contractAmount || 0}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>Предоплата:</td>
            <td>
              <input
                type="number"
                name="prepaymentAmount"
                value={editableData.prepaymentAmount || 0}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>Сумма договора с доп. расходами:</td>
            <td>{totalWithAdditionalExpenses().toFixed(2)}</td>
          </tr>
          <tr>
            <td>Остаток долга:</td>
            <td>{calculateRemaining().toFixed(2)}</td>
          </tr>
          <tr>
            <td>Уплачено:</td>
            <td>{actualPaidAmount.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Материалы:</td>
            <td>
              <input
                type="number"
                name="materialCosts"
                value={editableData.materialCosts || 0}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>Зарплата менеджера ({editableData.managerName || '—'}):</td>
            <td>
              <input
                type="number"
                name="managerSalary"
                value={editableData.managerSalary || 0}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>Зарплата замерщика ({editableData.surveyorName || '—'}):</td>
            <td>
              <input
                type="number"
                name="surveyorSalary"
                value={editableData.surveyorSalary || 0}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>Зарплата монтажника ({editableData.installerName || '—'}):</td>
            <td>
              <input
                type="number"
                name="installerSalary"
                value={editableData.installerSalary || 0}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>Дополнительные расходы:</td>
            <td>
              <input
                type="number"
                name="additionalExpenses"
                value={editableData.additionalExpenses || 0}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr className="profit-row">
            <td>Прибыль:</td>
            <td>{calculateProfit().toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <button className="save-button" onClick={handleSave}>Сохранить изменения</button>
    </div>
  );
}

export default Analytics;